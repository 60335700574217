@import url('constants.less');
@import  url('../App.less');


/* Body */
html,body{
    background-image: url("../assets/images/sepia_background.png");
    background-position:  center top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    
  
    //height:100%;
    //color: #000305;
     font-family: Futura, Helvetica, sans-serif;
/*      font-family: 'Oswald', sans-serif;*/
    //margin: 0;
    //padding: 0em;
    //text-align: left;
    //margin: 0em 0em 0em 0em;
   .unselectable;
  // background-color: #444444;

  }

Header {
    margin: 15vh 1.5em Auto 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //font-size: calc(10px + 2vmin);
    //color: white;
    color: #282c34;
}

.Home {
    .title
    {
        .allroundcorners(1em);
        padding: 1.5em;
        background-color: rgba(0,9, 0, .2);
        h2,h5{
            color:#dcdcdc
        }   
    }
    //border: 2px solid yellow;
    margin: 0;
    padding: 0;
    //background-color: #282c34;
    //background-color: #FFFFFF;
    

}

.app-header {
    //text-align: center;
    //background-color: #282c34;
    //background-color: #282c34;
    //height: 100%;
    //min-height: 50vh;
    //margin-top: 15vh;
    margin: 15vh 1.5em Auto 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //font-size: calc(10px + 2vmin);
    //color: white;
    color: #282c34;
}



.App-header {
    //border: 2px solid yellow;
    .App-header();
    h3,h5 {
        text-align: center;

      //  color: green;
    }
}
.business-card {

    margin:0 auto;// 0 50%;
    //transform: translateX(-50%);
    padding: 3%;
   // visibility: hidden;
    position: absolute;
    bottom: 70px;
    right: 0;
}

h3,h5 {

    text-align: center;
}




@media(max-Width:575px) {
    .business-card{
        //    visibility: visible
        margin:0 auto 0 50%;
        transform: translateX(-50%);
        right: auto;
    }
}

// @media(min-height:1000px) and (max-height:1400px) and (max-width:2000px){
    
//     html,body{
//         background-size: auto 100lvh;
//     }
// }

// @media(max-width:2000px) and (min-height:1400px) {
    
//     html,body{
//         background-size: auto 100lvh;
//     }
// }


// @media(min-width:1500px) {
    
//     html,body{
//         background-size: 100lvh auto;
//     }
// }


// @media(min-Width:1294px) and (min-height:200px) {
//     html,body{
//          background-size: 100lhw 100lhv;
//     }
// }

// @media(min-height:3401px) {
//     html,body{
//          background-size: auto 100lhv;
//     }
// }


